<template>
  <div class="my-account">
    <div class="header-menu va-row">
      <div class="flex md10 offset--md1">
        <div class="body-spacing">
          <div class="header-teacher">
            <div class="va-row">
              <div class="flex xs6 ddh-item">
                Dentro Educa – {{ teacherCredential.user.client_name }}
              </div>
              <div class="flex xs6 ddh-prof">
                <router-link class :to="{ name: 'main' }">
                  Encerrar sessão
                  <svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M14.2373 19.5721C13.625 20.1843 13.6415 21.1843 14.2373 21.7801C14.8478 22.3907 15.8348 22.3907 16.4453 21.7801L21.1279 17.0975C21.7385 16.4869 21.7385 15.5 21.1279 14.8895L16.4453 10.2068C15.8348 9.59627 14.8478 9.59627 14.2373 10.2068C13.6268 10.8173 13.627 11.8043 14.2373 12.4149L16.2465 14.4348H5.56522C4.70375 14.4348 4 15.1385 4 16C4 16.8615 4.70375 17.5652 5.56522 17.5652H16.2441L14.2373 19.5721Z"
                      fill="#FFFFFF" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M25.1304 4H6.86957C5.27682 4 4 5.29156 4 6.86957V8.78261C4 9.64408 4.70375 10.3478 5.56522 10.3478C6.42668 10.3478 7.13044 9.64408 7.13044 8.78261V8.17391C7.13044 7.6006 7.6006 7.13044 8.17391 7.13044H23.8261C24.3994 7.13044 24.8696 7.6006 24.8696 8.17391V23.8261C24.8696 24.3994 24.3994 24.8696 23.8261 24.8696H8.17391C7.6006 24.8696 7.13044 24.3994 7.13044 23.8261V23.2174C7.13044 22.3559 6.42668 21.6522 5.56522 21.6522C4.70375 21.6522 4 22.3559 4 23.2174V25.1304C4 26.7093 5.29071 28 6.86957 28H25.1304C26.7093 28 28 26.7093 28 25.1304V6.86957C28 5.29071 26.7093 4 25.1304 4Z"
                      fill="#FFFFFF" />
                  </svg>
                </router-link>

                <img
                  :src="teacherCredential.user.client_logo"
                  class="logo-client"
                  alt="logo"
                  width="120"
                />
              </div>
            </div>

            <div class="va-row">
              <div class="flex xs12 text-left school-name">
                <h4>
                  <!-- <router-link :to="{ name: 'grades' }">
										<span class="back-circle">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="36"
												height="36"
												viewBox="0 0 36 36"
											>
												<path
													fill="#AFA2BA"
													fill-rule="evenodd"
													stroke="#AFA2BA"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="1.6"
													d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.004 1.004 0 0 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"
												/>
											</svg>
										</span>
									</router-link> -->
                  <!-- {{ teacherCredential.name }} -->
                  {{ teacherCredential.name }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="teacher-grade va-row" v-if="teacherCredential">
        <div class="flex md10 offset--md1 xs12 menu-list">
          <div class="body-spacing">
            <div class="va-row">
              <div class="flex md8 xs12 no-padding">
                <menu-tabs
                  :items="[
                    { label: 'Minhas turmas', slug: 'grades', isActive: false },
                    {
                      label: 'Livros personalizados',
                      slug: 'infobook',
                      isActive: false,
                    },
                    {
                      label: 'Material complementar',
                      slug: 'extra',
                      isActive: true,
                    },
                  ]"
                  @scrollTo="scrollParent"
                  ref="tabs"
                  class="container-ddh-menu"
                ></menu-tabs>
              </div>

              <div class="flex md4 xs12 text-right no-padding">
                <router-link
                  class="btn btn-primary"
                  target="_blank"
                  :to="{ name: 'LoginStudent' }"
                >
                  ÁREA DO ALUNO 
                  <!-- <span class="fa fa-external-link"></span> -->
                  <svg width="24" height="24" class="fa svg" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M25.3333 24C25.3333 24.7333 24.7333 25.3333 24 25.3333H8C7.26667 25.3333 6.66667 24.7333 6.66667 24V8C6.66667 7.26667 7.26667 6.66667 8 6.66667H12.6667C13.4 6.66667 14 6.06667 14 5.33333C14 4.6 13.4 4 12.6667 4H6.66667C5.18667 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V19.3333C28 18.6 27.4 18 26.6667 18C25.9333 18 25.3333 18.6 25.3333 19.3333V24Z"
                      fill="#FFFFFF" />
                    <path
                      d="M17.7767 5.83333C17.7767 4.82386 18.6005 4 19.61 4H26.2767C27.2861 4 28.11 4.82386 28.11 5.83333V12.5C28.11 13.5095 27.2861 14.3333 26.2767 14.3333C25.2672 14.3333 24.4433 13.5095 24.4433 12.5V10.2538L13.1236 21.5736C12.4083 22.2888 11.2517 22.2888 10.5364 21.5736C9.82118 20.8583 9.82118 19.7017 10.5364 18.9864L21.8562 7.66667H19.61C18.6005 7.66667 17.7767 6.84281 17.7767 5.83333Z"
                      fill="#FFFFFF" />
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="body-teacher">
      <div class="va-row">
        <div class="flex md10 offset--md1">
          <div class="body-spacing">
            <!-- {{list}} -->

            <div>
              <div class="va-row">
                <div class="tab">Material complementar</div>

                <div class="panel item-list" v-if="books && books.length > 0">
                  <div
                    class="flex xs12 no-padding text-left books-list"
                    v-for="product in books"
                    :key="product.id"
                  >
                    <div class="va-row padding-book">
                      <div class="book-image xs2 flex">
                        <img :src="product.thumb" width="150" alt="" />
                      </div>
                      <div class="xs10 flex description-book">
                        <h5>
                          <span class="nome-da-marca">{{
                            product.brand.name
                          }}</span
                          ><br />{{ product.name }}
                        </h5>
                      </div>
                    </div>
                    <div
                      class="va-row item-extra"
                      v-for="file in product.extra_files"
                      :key="file.id"
                    >
                      <div class="flex xs12 text-left no-padding">
                        <div class="va-row">
                          <div class="flex md9">
                            <span class="tittle-style">{{ file.name }}</span>
                          </div>
                          <div class="flex md3 text-right">
                            <a
                              class="btn btn-white text-uppercase"
                              target="_blank"
                              :href="file.url"
                            >
                              <!-- <i class="fa fa-download"></i> -->BAIXAR</a
                            >
                          </div>
                        </div>

                        <div class="line-table"></div>
                        <div
                          class="description tittle-description"
                          v-html="file.description"
                        ></div>
                      </div>
                    </div>
                    <div class="line-table divider-table"></div>
                  </div>
                </div>

                <div class="panel item-list" v-else>
                  <div class="flex xs12 no-padding text-left books-list">
                    <div class="va-row">
                      <div class="flex xs12 text-center no-padding">
                        <br />
                        <p>
                          <b>Não encontramos nenhum material complementar</b
                          ><br />
                          <strong
                            >Esta escola não possui nenhum material complementar
                            no momento.</strong
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";

const axios = require("axios");

export default {
  name: "schools",
  directives: { mask },
  // components: { VuesticTreeCategory, VuesticTreeNode, VuesticTreeRoot },
  computed: {
    ...mapGetters({
      teacherCredential: "teacherCredential",
      // ...
    }),
  },

  data() {
    return {
      books: [],
    };
  },

  mounted() {
    const self = this;

    let config = {
      headers: {
        Authorization: "Bearer " + this.teacherCredential.token,
      },
    };

    axios
      .get(process.env.VUE_APP_ROOT_API + "/grades/extra_files", config)
      .then((response) => {
        //console.log(response);

        self.books = response.data.data.books;
        //self.selectedProduct = 0
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },

  //props: ['address', 'subscription'],

  methods: {
    showSubscriberEditing() {
      this.subscriberEditing = false;
    },
    hideSubscriberEditing() {
      this.subscriberEditing = false;
    },

    scrollParent(slug) {
      this.$router.push({ name: slug });
    },
  },
};
</script>
<style lang="scss" scoped>
.vuestic-tree-category {
  border-top: 1px solid #dfdae4;
  padding-top: 25px !important;
}

.books-list {
  position: relative;
  padding: 24px !important;
  .btn-white {
    background: #4965bc;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.57px;
    text-align: center;
    color: #ffffff;
    border: 0px;
    border-radius: 58px;
    padding: 16px;
    &:hover {
      color: white;
      background-color: #395099;
    }
  }

  h5 {
    margin-bottom: 20px;
  }
}

.item-list {
  font-family: "Nunito", "Helvetica Neue", Helvetica, Arial, sans-serif;

  h2 {
    color: #8f12b6;
    font-size: 24px;
    line-height: 25px;
    font-weight: bold;
    font-weight: 900;
    letter-spacing: 1.5;
    margin-top: 30%;
  }
  .books-list:last-child .divider-table {
    display: none;
  }
}

.questions {
  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #68596e;
  }

  ::v-deep .square-with-icon {
    background-color: transparent;
    color: #00dcde;
    font-size: 35px;
  }
}

.info-box {
  border-radius: 10px;
  background-color: #f8f7fc;
  height: 215px;
  border-radius: 10px;
  background-color: #f8f7fc;
  padding: 34px;
}
.btn-white {
  position: relative;
}
.flag {
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
  background-color: #4567ba;
  max-width: 310px;
  padding: 10px;
  width: 310px;
  position: absolute;
  color: #cbe1ff;
  font-size: 14px;
  font-family: "Nunito";
  border-radius: 10px;
  display: block;
  margin-left: -90px;
  text-align: left;
  font-weight: 200;
  bottom: 63px;

  p {
    margin-bottom: 0px !important;
  }

  .icon {
    align-items: center;
    display: flex;
  }

  b {
    color: #cbe1ff !important;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: -20px;
    width: 0px;
    height: 0px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #4567ba;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
.body-spacing {
  max-width: 1160px;
  margin: 0 auto;
}
::v-deep .box-blue {
  height: 215px;
  border-radius: 10px;
  background-color: #00dcde;
  padding: 25px;
  font-size: 16px;
  color: #ffffff;

  h1 {
    font-family: Nunito;
    font-size: 60px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
}
::v-deep .vuestic-tabs .nav {
  padding-top: 0px !important;
}

::v-deep .vuestic-tabs {
  padding: 0px !important;
}

.phrase-secure {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #68596e;
  margin-top: 20px !important;
  // margin-bottom:20px !important;

  b {
    color: #68596e !important;
  }

  .btn {
    height: 65px;
    width: 100%;
    max-width: 357px;
    font-size: 14px;
    border-radius: 100px;
  }
}

.custom-teacher-table {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #afa2ba;

  b {
    font-size: 14px !important;
  }

  .header-table {
    height: 61.5px;
    background-color: #f8f7fc;
    border-top: solid 1px #dfdae4;
    line-height: 33px;
  }
}
.teacher-flag {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background-color: #9378f0;
  line-height: 30px;

  a {
    font-weight: bold;
    text-decoration: underline;
    color: white;
  }
}
.book-covers {
  justify-content: flex-end;
}
.empty-book {
  width: 100%;
  border: 1px solid #afa2ba;
  height: 100%;
  height: 187px;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  display: flex;
  font-size: 25px;
}
.btn-micro-edit {
  font-family: "Nunito";
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.67px;
  text-align: center;
  color: #4965bc;
  background: none;
  border: none;
}
.student-name {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #68596e;

  justify-content: center;
  flex-direction: column;
  text-align: left;
  display: flex;
}
.student-code {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 1.11px;
  text-align: right;
  color: #68596e;

  justify-content: center;
  flex-direction: column;
  text-align: right;
  display: flex;
}

.panel-gray {
  border-radius: 10px;
  border: solid 1px #dfdae4;
  background-color: #ffffff;
  overflow: hidden;

  .btn {
    border-radius: 100px;
    background-color: #4965bc;
    height: 65px;
    border-radius: 100px;
    background-color: #4965bc;
    padding-left: 70px;
    position: relative;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 40px;

    span {
      width: 45px;
      height: 45px;
      border-radius: 100px;
      background-color: #ffffff;
      font-size: 41px;
      display: inline-block;
      color: #4965bc;
      line-height: 43px;
      position: absolute;
      left: 8px;
      top: 8px;
    }
  }
}

.body-teacher {
  margin-top: 20px;

  .body-spacing .panel {
    h4 {
      margin-top: 10px;
      margin-bottom: 15px;
      font-family: Nunito;
      font-size: 20px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #66596e;
    }
  }
}

.body-student {
  border-bottom: 1px solid #dfdae4;
}

.info-grade {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #68596e;
}

.school-name {
  font-family: "Nunito";
  font-size: 40px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 10px;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 10px;
  margin-top: 10px;

  h4 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 0;
    // line-height: 70px;
  }

  span {
    font-size: 16px;
    font-weight: 200 !important;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .back-circle {
    width: 60px;
    height: 60px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    // display: inline-block;
    text-align: center;
    border-radius: 30px;
    position: relative;
    float: left;
    margin-right: 10px;
    top: 0px;
    margin-top: 0px;

    svg {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      margin: auto;
    }
  }
}

.my-account {
  position: absolute;
  top: 0px;
  background-color: #f8f7fc;
  z-index: 3;
  width: 100%;
  padding-bottom: 30px;
  min-height: 100%;
  overflow: hidden;
  // height: 100%;
}

.header-teacher {
  position: relative;

  .ddh-item {
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
  }

  .ddh-prof {
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;

    a {
      border: 0;
      background: none;
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #ffffff;
    }
  }
}

h4 {
  font-family: "Nunito";
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1.3px;
  color: #000000;
  margin-bottom: 0;
}
.header-menu {
  background-color: #00cf72;
  margin-bottom: 20px;
  display: block;

  .body-spacing {
    max-width: 1160px;
    margin: 0 auto;
  }

  .teacher-grade {
    background-color: white;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #68596e;
    box-shadow: 0 10px 25px 0 #e4d9ee;

    .menu-list {
      padding-bottom: 0px !important;
    }

    .btn {
      width: 275px;
      height: 45px;
      border-radius: 100px;
      // background-color: #9378f0;
      background-image: none;
      position: relative;
      line-height: 20px;
      top: 4px;

      @media (max-width: 767px) {
        display: block;
        margin: 0 auto;
        top: 15px;
      }

      &:hover {
        // background-color: #9378f0;
      }
      span {
        font-size: 17px;
        position: absolute;
        top: 15px;
        right: 23px;
      }

      svg {
            position: absolute;
              right: 20px;
              top: 10px;
      }
    }

    .teacher-name {
      line-height: 45px;
    }
  }

  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.7;
    letter-spacing: normal;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 990px) {
      font-size: 18px;
    }
  }

  .logo-client {
    position: absolute;
    bottom: 0px;
    display: block;
    right: 0px;
    margin: 0px;
    float: right;

    @media (max-width: 990px) {
      display: none;
    }
  }

  .school-name-short {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    color: #68596e;
    display: flex;
    align-items: center;
  }

  .school-name {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 10px;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 10px;
    margin-top: 10px;
    align-items: center;
    display: flex;

    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 10px;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 20px;

    h4 {
      font-family: "Nunito";
      font-size: 40px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #ffffff;
      margin-top: 0;
      display: flex;
      align-items: center;
      // line-height: 70px;
    }

    span {
      font-size: 16px;
      font-weight: 200 !important;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .back-circle {
      width: 60px;
      height: 60px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      // display: inline-block;
      text-align: center;
      border-radius: 30px;
      position: relative;
      float: left;
      margin-right: 10px;
      top: 0px;
      margin-top: 0px;
      margin-bottom: 0px;

      svg {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        margin: auto;
      }
    }
  }

  .header-teacher {
    position: relative;

    .ddh-item {
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff;
    }

    .ddh-prof {
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #ffffff;

      a {
        border: 0;
        background: none;
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #ffffff;
      }
    }
  }
}

.error-label {
  color: red;
  text-align: left;
  margin-bottom: 10px;
}

h5 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #68596e;
}

// .address-info{
//   margin-bottom:15px;
// }
// .editing{
//   margin-top:15px;
// }
// .radio-button-wrapper{
//   padding: 14px 11px;
//   margin-top: 10px;

//   @media(max-width: 990px){
//     padding: 20px 19px;
//     margin-top: 10px;
//   }
// }
.header-menu-club {
  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.7;
    letter-spacing: normal;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 990px) {
      font-size: 18px;
    }
  }
}

// Default of page

// .my-data{

//   /* Facebook bar */
//   .facebook-tag{
//     height: 65px;
//     border-radius: 5px;
//     background-color: #3a5998;
//     color:white;
//     padding: 14px 20px;
//     margin-bottom: 20px;

//     svg{
//       font-size: 26px;
//       margin-right: 0px;
//       float: left;

//       path{
//         fill: #fff;
//       }
//     }

//     .name{
//       display: inline-block;
//       margin-top: 1px;
//       line-height: 37px;
//     }

//     .facebook-toggle{
//       float: right;
//       display: block;
//       margin-top: 5px;
//     }
//   }

// }

// .change-password{
//   border-radius: 10px;
//   border: solid 2px #f0ebf5;
//   background-color: #ffffff;
//   padding: 24px 20px;
//   position: relative;

//   h4{
//     font-size: 16px;
//     color: #000000;
//     margin-top: 0px;
//     margin-bottom: 7px;
//     font-family: 'Roboto';

//     font-weight: normal;
//     font-style: normal;
//     font-stretch: normal;
//     letter-spacing: normal;
//   }

//   h5{
//     font-size: 14px;
//     font-weight: normal;
//     font-style: normal;
//     font-stretch: normal;
//     letter-spacing: normal;
//     color: #afa2ba;
//     margin-top: 0px;
//     margin-bottom: 0px;
//   }

//   .floated-icon{
//     float: left;
//     width: 36px;
//     height: 36px;
//     font-size:30px;

//     svg{
//       position: relative;
//       top: -5px;
//       left: -3px;

//       path{
//         fill: #afa2ba;
//       }
//     }
//   }

//   .new-password-group{
//     margin-top: 20px;
//     display: block;
//   }

//   .editor{
//     right: 18px;
//     top: 20%;
//   }

//   ::v-deep .form-group{
//     margin-bottom: 20px;
//     padding: 22px 20px;

//     ::v-deep &:last-child{
//       margin-bottom:20px;
//     }
//   }

// }

// .my-data-table{
//   h4{
//     margin-top: 50px;
//     margin-bottom: 20px;
//   }
// }

// .fiscal-notes{
//   border-radius: 10px;
//   border: solid 1px #f0ebf5;
//   background-color: #ffffff;
//   position: relative;
//   border: solid 2px #f0ebf5;

//   h4{
//     font-size: 16px;
//     color: #000000;
//     margin-top: 0px;
//     margin-bottom: 10px;
//     font-family: 'Roboto';
//     font-weight: bold;
//     font-style: normal;
//     font-stretch: normal;
//     line-height: normal;
//     letter-spacing: normal;
//   }

//   .body-spacing{
//     padding: 30px 30px 10px 30px;

//     @media(max-width:990px){
//       padding: 25px 15px 15px 15px;
//     }

//     //  div:last-child{
//     //   margin-bottom: 0px;
//     // }
//   }

//   hr{
//     border-top: solid 1px #f0ebf5;
//     margin-top: 0px;
//     margin-bottom: 0px;
//   }

//   .dual-inputs{
//     padding: 0px 8px;
//     margin-bottom: 20px;
//   }

//   .new-password-group{
//     margin-top: 20px;
//     display: block;
//   }

//   .editor{
//     right: 18px;
//     top: 20%;
//   }

//   .company-code{
//     text-transform: capitalize;
//   }

//   ::v-deep .form-group{
//     margin-bottom: 20px;
//     padding: 22px 20px;

//     ::v-deep &:last-child{
//       margin-bottom:20px;
//     }
//   }

//   // .panel:last-child{
//   //   margin-bottom: 0px;
//   // }
// }

// .address-boxes{
//   border-radius: 10px;
//   border: solid 1px #f0ebf5;
//   background-color: #ffffff;
//   position: relative;
//   border: solid 2px #f0ebf5;

//   h4{
//     font-size: 16px;
//     color: #000000;
//     margin-top: 0px;
//     margin-bottom: 10px;
//     font-family: 'Roboto';
//     font-weight: bold;
//     font-style: normal;
//     font-stretch: normal;
//     line-height: normal;
//     letter-spacing: normal;
//   }

//   svg{
//     path{
//       fill: #afa2ba;
//     }
//   }

//   .clube{
//     svg{
//     path{
//         fill: #fff;
//       }
//     }
//   }

//   .body-spacing{
//     padding: 30px 30px;

//     @media(max-width:990px){
//       padding: 25px 15px 15px 15px;
//     }

//   }

//   hr{
//     border-top: solid 2px #f0ebf5;
//   }

//   .dual-inputs{
//     padding: 0px 8px;
//     margin-bottom: 20px;
//   }

//   .new-password-group{
//     margin-top: 20px;
//     display: block;
//   }

//   .editor{
//     right: 18px;
//     top: 20%;
//   }

//   ::v-deep .form-group{
//     margin-bottom: 20px;
//     padding: 22px 20px;

//     ::v-deep &:last-child{
//       margin-bottom:20px;
//     }
//   }

//   .more-address{
//     border-radius: 10px;
//     border: solid 1px #f0ebf5;
//     background-color: #ffffff;
//   }

//   .address-row{
//     padding: 16px 0px;
//     position: relative;
//     border-bottom: solid 1px #f0ebf5;
//     b{
//       font-size: 16px;
//       color: #000000;
//     }
//     :last-child{
//       border-bottom: none;
//     }

//   }

// }

.address-row {
  position: relative;
}

.more-cards {
  .more-address {
    border-radius: 10px;
    border: solid 1px #f0ebf5;
    background-color: #ffffff;
  }
}

.panel {
  // height: 200px;
  border-radius: 0px 0px 10px 10px;
  // border: solid 2px #9378f0;
  background-color: #ffffff;
  // overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0 10px 25px 0 #e2ddf6;
  background-color: #ffffff;
  margin-top: 2px;
  position: relative;
  z-index: 999;
  width: 100%;

  border-left: 1px solid #dfdae4;
  border-right: 1px solid #dfdae4;
  border-bottom: 1px solid #dfdae4;

  .header-panel {
    padding: 0px 20px;
    background-color: #9378f0;
    line-height: 33px;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .clube img {
    margin-top: -5px;
  }

  b {
    font-size: 16px;
    color: #000000;
  }

  .panel-body {
    position: relative;
    padding: 10px 0px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #afa2ba;
    margin: 0px !important;

    .flex.xs1 {
      -ms-flex-preferred-size: 8.33333%;
      flex-basis: 6.33333%;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-width: 6.33333%;
    }

    b {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #000000;
    }

    .editor {
      right: 18px;
      top: 20%;
    }
  }
}

.subscription-box {
  // padding: 30px !important;

  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #000000;

  .toggled {
    margin-top: 10px;
  }
}
.newsletter {
  // margin-top:40px;
  padding: 31px;
  border: solid 2px #f0ebf5;

  @media (max-width: 990px) {
    margin-top: 0px;
    padding: 15px;
  }
}

// Custom Component
.v-switch-button {
  ::v-deep &:before {
    content: "";
    display: block;
    position: relative;
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 14.5px;
    margin-left: auto;
    margin-right: auto;
    top: 9px;
  }
}

.vue-js-switch {
  &.toggled {
    .v-switch-button {
      ::v-deep &:before {
        content: "";
        display: block;
        position: relative;
        background-color: #7ed321;
        width: 6px;
        height: 6px;
        border-radius: 14.5px;
        margin-left: auto;
        margin-right: auto;
        top: 9px;
      }
    }
  }
}

.name-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}
.lastname-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}

.no-padding-bottom {
  padding-bottom: 0px !important;
}

.logo-client {
  position: absolute;
  bottom: 0px;
  display: block;
  right: 0px;
  margin: 0px;
  float: right;

  @media (max-width: 990px) {
    display: none;
  }
}

.tab {
  /* color/neutral/light */

  background: #f4f2fb;
  border: 1px solid #dfdae4;
  border-radius: 16px 16px 0px 0px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #68596e;
  padding: 20px 22px;
}

.item-extra {
  /* color/neutral/lightest */

  background: #ffffff;
  /* color/neutral/medium */

  border: 1px solid #dfdae4;
  border-radius: 8px;
  padding: 16px;
  padding-top: 8px;
  margin-bottom: 16px !important;
}
.nome-da-marca {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #68596e;
}
.book-image {
  max-width: 20%;
}
.line-table {
  height: 1px;
  background-color: #dfdae4;
  margin-top: 8px;
  margin-bottom: 16px;
  &.divider-table {
    margin-top: 16px;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: -12px;
  }
}
.description-book {
  display: flex;
  align-items: center;
}
.padding-book {
  padding: 0px 0px 8px 0px;
}
.tittle-style {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #68596e;
  display: flex;
  align-items: center;
  height: 100%;
}

.tittle-description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #afa2ba;
}
.va-row:last-child {
  margin-bottom: 0px !important;
}
</style>
